@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@300;500;700&display=swap"); */
script + iframe {
  display: none !important;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
* {
  font-family: Montserrat;
}
input {
  margin-bottom: 5px;
}
.bnbicon {
  width: 20px;
  margin-top: -3px;
  margin-left: 10px;
  margin-right: 3px;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
}
script + iframe {
  display: none;
}

.main {
  background-size: cover;
  min-height: 50vh;
}
.adminbg {
  background-image: url("/static/media/Ellipse 18.9a01c939.png");
  background-color: black;
  background-size: cover;
}
.adminbtn {
  background: linear-gradient(
    92deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.24) 100%
  );
  border: none;
  -webkit-backdrop-filter: blur(57px);
          backdrop-filter: blur(57px);
  border-radius: 33px;
  color: white !important;
  padding: 10px 20px !important;
}
.fullheight {
  min-height: 90vh;
}

.box {
  background-color: #343444;
  border-radius: 20px;
  padding: 20px;
  margin-top: 10px;
  color: #eee;
  transition: all 0.3s ease-in-out;
}
.box:hover {
  transform: translateY(-10px);
  transition: all 0.4s ease;
}
.box:hover img.thumb {
  transform: scale(1.03);
}
img.thumb {
  border-radius: 20px;
  margin: auto;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 10px;
  transition: all 0.4s ease;
}
.main {
  min-height: 100vh;
}
.box .date {
  display: block;
  letter-spacing: 1px;
  margin-top: 10px;
}
.box .date i {
  color: #f3ba2f;
}
.box .desc {
  color: #b5b5b5;
  display: block;
  overflow: hidden;
}
.box .desc.description {
  height: 60px;
}
.bnbcoin {
  height: 15px;
  margin-right: 5px;
}
.box .pricebox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: end;
  text-align: right;
  padding-left: 0;
}
.box .price {
  font-weight: 700;
  display: inline;
  margin: 0;
  padding: 0;
}
.box .label {
  min-width: 100%;
}
.box hr {
  margin: 5px 0;
}
.btn-theme {
  background-color: #f3ba2f;
  font-weight: 700;
}
.titlebox {
  padding-right: 0;
}
.modal-content {
  background-color: #343444;
}
.btn-close {
  background-color: white;
}
.modal-body {
  margin-bottom: 24px;
}
.userHeader a {
  color: white;
  text-decoration: none;
  padding: 20px !important;
  display: inline-block;
}
.userHeader a:hover {
  color: #f3ba2f;
  text-decoration: none;
}
.connectwallet {
  /* margin-left: auto; */
  float: right;
}
.clear {
  clear: both;
}
.progressWrap {
  position: fixed;
  bottom: 10px;
  z-index: 100;
  left: 50%;
  width: 95vw;
  max-width: 500px;
  transform: translateX(-50%);
  background-color: #343444;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 5px #000;
}
.darkbg {
  background-color: #000;
  background-image: url("/static/media/Ellipse 18.9a01c939.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  font-family: "Inter";
  font-weight: 700;
}
h2 {
  font-family: "Inter";
  font-weight: 300;
  color: #fff;
}

.container {
  /* max-width: 100%; */
  /* width: 1400px !important; */
}
.Backgroundnav {
  background-image: url(/static/media/Background-Hero.4b814fb2.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.Background {
  background-image: url(/static/media/Background-Hero.4b814fb2.png);

  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  background-position: bottom center;
}
.hero {
  max-width: 100%;
  /* width: 700px !important; */
  /* margin-left: 90px; */
}

.nft {
  font-size: 50px;
  color: #fff;
  /* padding-top: 90px; */
}
.nft1 {
  font-size: 35px;
  color: white;
}
.sub-head {
  font-size: 22px;
}
.btn1 {
  width: 143px;
  height: 52px;
  left: 10px;
  top: 293px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  font-family: "Inter";
  color: white;

  background: linear-gradient(102.4deg, #d03042 40.99%, #a02bca 104.66%);
  border-radius: 52.8667px;
}
.btn2 {
  width: 123.07px;
  height: 52px;
  left: 165.13px;
  /* top: 293px; */
  font-size: 18px;
  font-weight: 700;
  font-family: "Inter";

  background: linear-gradient(
    102.4deg,
    rgba(208, 48, 66, 0) 40.99%,
    rgba(255, 126, 141, 0) 104.66%
  );
  border: 2px solid #ffffff;
  border-radius: 52.8667px;
  color: white;
}
.gap {
  padding-top: 90px;
}
.text {
  font-size: 16px;
  font-weight: 300;
}
.background-1 {
  background-image: url("/static/media/Body Background.e0e0dfab.png");
  background-size: cover;
}
.group {
  max-width: 100%;
  width: 250px !important;
}
.gap1 {
  padding-top: 290px;
  margin-top: -120px;
  padding-bottom: 70px;
}
.background-2 {
  background-image: url("/static/media/Ellipse 18.9a01c939.png");
  background-size: cover;
  background-color: #000000;
  background-position: center;
}
.gap2 {
  padding-top: 90px;
  cursor: pointer;
}
.grp1 {
  max-width: 100%;
  width: 450px !important;
}
.bg {
  background-image: url("/static/media/Rectangle 54.127bc54e.png");
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  padding: 60px;
}
.nft-1 {
  font-size: 35px;
  color: white;
  padding-top: 90px;
  padding-bottom: 20px;
}
.text-1 {
  font-size: 17px;
  font-weight: 300;
}
.nfta {
  max-width: 100%;
  width: 450px !important;
}
.nft-3 {
  font-size: 35px;
  color: white;
  padding-top: 70px;
}
.bg3 {
  background-image: url(/static/media/purple.4bba16ce.png);
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  padding: 60px;
}
.seven {
  max-width: 100%;
  width: 900px !important;
}
.background-nav {
  /* background-image: url(../Images/Header.png); */
  background-size: cover;
  /* background-color: #d03042; */
}
.nav-link {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  color: white !important;
}
.dropdown-menu a {
  color: black !important;
}
.logo {
  max-width: 100%;
  width: 75px !important;
}
@media only screen and (max-width: 600px) {
  .nft {
    font-size: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .btn2 {
    /* margin-top: 20px; */
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .set {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .grp1 {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .bg {
    padding: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .text-1 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .btn1 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .bg3 {
    padding: 20px;
  }
}
.herofirst {
  min-height: 50vh;
}
.com {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.my-price-token {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.main-bg {
  background-image: url(/static/media/Bg-main.14bfc570.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 15px;
  border-radius: 20px;
}
.gap {
  display: flex;
  justify-content: space-evenly;
}
.btn-5 {
  display: inline-block;
  width: 100%;
  color: white !important;
  text-decoration: none;
  background: linear-gradient(130.35deg, #d03042 17.35%, #bb2ca2 78.23%);
  border-radius: 10px;
  border: none;
  padding: 6px !important;
}
.mar {
  margin-bottom: -30px;
}

.nf {
  color: white;
  font-size: 20px;
  font-weight: 700;
}
.txti {
  color: #34d2c9;
  font-size: 14px;
  font-weight: 500;
}
.d-flex {
  flex-direction: row;
  justify-content: space-between;
}
.main-bg .content p {
  height: 120px;
  overflow: hidden;
}
.iii {
  color: white;
}
.text-right {
  text-align: right;
}

