/* @import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@300;500;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap");
script + iframe {
  display: none !important;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
* {
  font-family: Montserrat;
}
input {
  margin-bottom: 5px;
}
.bnbicon {
  width: 20px;
  margin-top: -3px;
  margin-left: 10px;
  margin-right: 3px;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
}
script + iframe {
  display: none;
}
