.box {
  background-color: #343444;
  border-radius: 20px;
  padding: 20px;
  margin-top: 10px;
  color: #eee;
  transition: all 0.3s ease-in-out;
}
.box:hover {
  transform: translateY(-10px);
  transition: all 0.4s ease;
}
.box:hover img.thumb {
  transform: scale(1.03);
}
img.thumb {
  border-radius: 20px;
  margin: auto;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 10px;
  transition: all 0.4s ease;
}
.main {
  min-height: 100vh;
}
.box .date {
  display: block;
  letter-spacing: 1px;
  margin-top: 10px;
}
.box .date i {
  color: #f3ba2f;
}
.box .desc {
  color: #b5b5b5;
  display: block;
  overflow: hidden;
}
.box .desc.description {
  height: 60px;
}
.bnbcoin {
  height: 15px;
  margin-right: 5px;
}
.box .pricebox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: end;
  text-align: right;
  padding-left: 0;
}
.box .price {
  font-weight: 700;
  display: inline;
  margin: 0;
  padding: 0;
}
.box .label {
  min-width: 100%;
}
.box hr {
  margin: 5px 0;
}
.btn-theme {
  background-color: #f3ba2f;
  font-weight: 700;
}
.titlebox {
  padding-right: 0;
}
.modal-content {
  background-color: #343444;
}
.btn-close {
  background-color: white;
}
.modal-body {
  margin-bottom: 24px;
}
.userHeader a {
  color: white;
  text-decoration: none;
  padding: 20px !important;
  display: inline-block;
}
.userHeader a:hover {
  color: #f3ba2f;
  text-decoration: none;
}
.connectwallet {
  /* margin-left: auto; */
  float: right;
}
.clear {
  clear: both;
}
.progressWrap {
  position: fixed;
  bottom: 10px;
  z-index: 100;
  left: 50%;
  width: 95vw;
  max-width: 500px;
  transform: translateX(-50%);
  background-color: #343444;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 5px #000;
}
.darkbg {
  background-color: #000;
  background-image: url(../../Images/Ellipse\ 18.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
