.main {
  background-size: cover;
  min-height: 50vh;
}
.adminbg {
  background-image: url(../../Images/Ellipse\ 18.png);
  background-color: black;
  background-size: cover;
}
.adminbtn {
  background: linear-gradient(
    92deg,
    rgba(255, 255, 255, 0.12) 0%,
    rgba(255, 255, 255, 0.24) 100%
  );
  border: none;
  backdrop-filter: blur(57px);
  border-radius: 33px;
  color: white !important;
  padding: 10px 20px !important;
}
.fullheight {
  min-height: 90vh;
}
